import { HttpErrorResponse } from '@angular/common/http';
import { Links, Meta, RequestOptions } from '@gridscale/gsclient-js';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ApplicationInstanceActions from './service_marketplace.actions';
import * as _ from 'lodash';
import { MarketplaceApplicationDetailResponse, MarketplaceApplicationListRow, MarketplacePlanDetailResponse, MarketplaceVersionDetailResponse, MarketplaceApplicationInstanceDataRow, MarketplaceApplicationInstanceDetailData, MarketplaceApplicationInstanceDetailResponse } from '@gridscale/gsclient-js';

export const featureKey = 'service_marketplace';

export const listAdapter = createEntityAdapter<MarketplaceApplicationInstanceDataRow>({
  selectId: a => a.id, // define "primary id"
  sortComparer: false
});

export const adapter = createEntityAdapter<MarketplaceApplicationInstanceDetailResponse>({
  selectId: a => a.data!.id!,
  sortComparer: false
});

export const applicationListAdapter = createEntityAdapter<MarketplaceApplicationListRow>({
  selectId: a => a.id,
  sortComparer: false
});

// export const planListAdapter = createEntityAdapter<PlanListRow>({
//   selectId: a => a.id,
//   sortComparer: false
// });

export const planAdapter = createEntityAdapter<MarketplacePlanDetailResponse>({
  selectId: a => a.data.id,
  sortComparer: false
});

export const versionAdapter = createEntityAdapter<MarketplaceVersionDetailResponse>({
  selectId: a => a.data.id,
  sortComparer: false
});

export const applicationAdapter = createEntityAdapter<MarketplaceApplicationDetailResponse>({
  selectId: a => a.data.id,
  sortComparer: false
});

export const listAdapterInitialState = listAdapter.getInitialState({
  listConfig: {
    limit: 100,
    sort: '-name',
    fields: ['name']
  },
  loaded: false,
  loading: false,
  loadingNext: false
}) as ListState;

export const applicationListInitialState = applicationListAdapter.getInitialState({
  listConfig: {
    limit: 100,
    sort: '-name',
    fields: ['name']
  },
  loaded: false,
  loading: false,
  loadingNext: false
});


export const planDetailInitialState = planAdapter.getInitialState({
  loaded: {},
  loading: {}
});

export const versionDetailInitialState = versionAdapter.getInitialState({
  loaded: {},
  loading: {}
});

export const applicationInitialState = applicationAdapter.getInitialState({
  loaded: {},
  loading: {},
});

export const initialState = adapter.getInitialState({
  list: listAdapterInitialState,
  loaded: {},
  loading: {},
  applicationList: applicationListInitialState,
  application: applicationInitialState,
  planList: {},
  plan: planDetailInitialState,
  version: versionDetailInitialState
}) as State;

export interface ListState extends EntityState<MarketplaceApplicationInstanceDataRow> {
  listConfig: RequestOptions;
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: Links<MarketplaceApplicationInstanceDataRow>;
  error?: Error | HttpErrorResponse | undefined;
}

export interface ApplicationListState extends EntityState<MarketplaceApplicationListRow> {
  listConfig: RequestOptions;
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: Links<MarketplaceApplicationListRow>;
  error?: Error | HttpErrorResponse | undefined;
}

export interface ApplicationState extends EntityState<MarketplaceApplicationDetailResponse> {
  loaded: Record<string, boolean>;
  loading: Record<string, boolean>;
}

// export interface PlanListState extends EntityState<PlanListRow> {
//   listConfig: RequestOptions;
//   loaded: boolean;
//   loading: boolean;
//   loadingNext: boolean;
//   meta?: Meta;
//   links?: Links<PlanListRow>;
//   error?: Error |error: Error | HttpErrorResponse | undefined;
// }

export interface PlanDetailState extends EntityState<MarketplacePlanDetailResponse> {
  loaded: Record<string, boolean>;
  loading: Record<string, boolean>;
}

export interface VersionDetailState extends EntityState<MarketplaceVersionDetailResponse> {
  loaded: Record<string, boolean>;
  loading: Record<string, boolean>;
}

export interface State extends EntityState<MarketplaceApplicationInstanceDetailResponse> {
  loaded: Record<string, boolean>;
  loading: Record<string, boolean>;
  list: ListState;
  applicationList: ApplicationListState;
  application: ApplicationState;
  // planList: Record<string, PlanListState>;
  plan: PlanDetailState;
  version: VersionDetailState;
}

export const reducer = createReducer(
  initialState,

  on(ApplicationInstanceActions.setApplicationInstancesListConfig, (state, action) => {
    if (_.isEqual(_.get(state, 'list.listConfig', {}), action.config)) {
      return state;
    }
    return {
      // update list config / empty collection
      ...state,

      list: listAdapter.removeAll({
        ...state.list,
        loaded: false,
        listConfig: {
          // ...state.listConfig,
          ...action.config
        }
      })
    };
  }),

  on(ApplicationInstanceActions.loadApplicationInstances, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      loading: true
    }
  })),

  on(ApplicationInstanceActions.loadApplicationInstancesSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,

    list: listAdapter.setAll(_.toArray(action.result?.data), {
      ...state.list,
      meta: {
        count: action.result?.meta?.count !== undefined ? parseInt(action.result.meta.count, 10) : action.result.data!.length,
        limit: action.result?.meta?.limit !== undefined ? parseInt(action.result.meta.limit, 10) : 10,
        offset: action.result?.meta?.page !== undefined && action.result?.meta?.limit !== undefined ? (parseInt(action.result.meta.page, 10) - 1) * parseInt(action.result.meta.limit, 10) : 0,
        page: action.result?.meta?.page !== undefined ? parseInt(action.result.meta.page, 10) - 1 : 0,
        total: action.result?.meta?.total !== undefined ? parseInt(action.result.meta.total, 10) : action.result.data!.length,
      },
      loading: false,
      loaded: true,
      error: undefined
    })
  })),

  on(ApplicationInstanceActions.loadApplicationInstancesFailure, (state, action) => ({
    ...state,

    list: listAdapter.removeAll({
      ...state.list,
      loading: false,
      loaded: true,
      error: action.error
    })
  })),

  on(ApplicationInstanceActions.loadApplicationInstance, (state: State, action) => ({
    ...state,
    loading: {
      ...state.loading,
      [action.uuid]: true
    },
  })),

  on(ApplicationInstanceActions.loadApplicationInstanceSuccess, (state: State, action) => adapter.setOne(action.result, {
    ...state,
    loading: {
      ...state.loading,
      [action.uuid]: false
    },
    loaded: {
      ...state.loaded,
      [action.uuid]: true
    }
  })),

  on(ApplicationInstanceActions.loadApplicationInstanceFailure, (state: State, action) => ({
    ...state,
    loading: {
      ...state.loading,
      [action.uuid]: false
    },
  })),

  /********** Applications */

  on(ApplicationInstanceActions.setApplicationsListConfig, (state, action) => {
    if (_.isEqual(_.get(state, 'applicationList.listConfig', {}), action.config)) {
      return state;
    }
    return {
      // update list config / empty collection
      ...state,

      applicationList: applicationListAdapter.removeAll({
        ...state.applicationList,
        loaded: false,
        listConfig: {
          // ...state.listConfig,
          ...action.config
        }
      })
    };
  }),

  on(ApplicationInstanceActions.loadApplications, (state: State, action) => ({
    ...state,
    applicationList: {
      ...state.applicationList,
      loading: true
    }
  })),

  on(ApplicationInstanceActions.loadApplicationsSuccess, (state: State, action) => ({
    ...state,
    applicationList: applicationListAdapter.setAll(_.toArray(action.result.data), {
      ...state.applicationList,
      meta: {
        count: action.result?.meta?.count !== undefined ? parseInt(action.result.meta.count, 10) : action.result.data!.length,
        limit: action.result?.meta?.limit !== undefined ? parseInt(action.result.meta.limit, 10) : 10,
        offset: action.result?.meta?.page !== undefined && action.result?.meta?.limit !== undefined ? (parseInt(action.result.meta.page, 10) - 1) * parseInt(action.result.meta.limit, 10) : 0,
        page: action.result?.meta?.page !== undefined ? parseInt(action.result.meta.page, 10) - 1 : 0,
        total: action.result?.meta?.total !== undefined ? parseInt(action.result.meta.total, 10) : action.result.data!.length,
      },
      loading: false,
      loaded: true
    })
  })),

  on(ApplicationInstanceActions.loadApplicationsFailure, (state: State, action) => ({
    ...state,
    applicationList: {
      ...state.applicationList,
      loading: false
    }
  })),


  on(ApplicationInstanceActions.loadApplication, (state: State, action) => ({
    ...state,
    application: {
      ...state.application,
      loading: {
        ...state.loading,
        [action.uuid]: true
      },
    }
  })),

  on(ApplicationInstanceActions.loadApplicationSuccess, (state: State, action) => ({
    ...state,
    application: applicationAdapter.setOne(action.result, {
      ...state.application,
      loading: {
        ...state.loading,
        [action.uuid]: false
      },
      loaded: {
        ...state.loaded,
        [action.uuid]: true
      }
    })
  })),

  on(ApplicationInstanceActions.loadApplicationFailure, (state: State, action) => ({
    ...state,
    application: {
      ...state.application,
      loading: {
        ..._.get(state, 'application.loading', {}),
        [action.uuid]: false
      },
    }
  })),



  // on(ApplicationInstanceActions.loadPlans, (state: State, action) => ({
  //   ...state,
  //   planList: {
  //     ...state.planList,
  //     [action.versionUuid]: {
  //       ..._.get(state.planList, [action.versionUuid], planListInitialState),
  //       loading: true
  //     }
  //   }
  // })),

  // on(ApplicationInstanceActions.loadPlansSuccess, (state: State, action) => ({
  //   ...state,
  //   planList: {
  //     ...state.planList,
  //     [action.versionUuid]: planListAdapter.setAll(action.result.data, {
  //       ..._.get(state.planList, [action.versionUuid], planListInitialState),
  //       loading: false,
  //       loaded: true
  //     })
  //   }
  // })),

  // on(ApplicationInstanceActions.loadPlansFailure, (state: State, action) => ({
  //   ...state,
  //   planList: {
  //     ...state.planList,
  //     [action.versionUuid]: {
  //       ..._.get(state.planList, [action.versionUuid], planListInitialState),
  //       loading: false
  //     }
  //   }
  // })),

  /************ Plan */

  on(ApplicationInstanceActions.loadPlan, (state: State, action) => ({
    ...state,
    plan: {
      ...state.plan,
      loading: {
        ...state.plan.loading || {},
        [action.planUuid]: true
      }
    }
  })),

  on(ApplicationInstanceActions.loadPlanSuccess, (state: State, action) => ({
    ...state,
    plan: planAdapter.setOne(action.result, {
      ...state.plan,
      loading: {
        ...state.plan.loading || {},
        [action.planUuid]: false
      },
      loaded: {
        ...state.plan.loaded || {},
        [action.planUuid]: true
      }
    })
  })),

  on(ApplicationInstanceActions.loadPlanFailure, (state: State, action) => ({
    ...state,
    plan: {
      ...state.plan,
      loading: {
        ...state.plan.loading || {},
        [action.planUuid]: false
      }
    }
  })),


  on(ApplicationInstanceActions.loadVersion, (state: State, action) => ({
    ...state,
    version: {
      ...state.version,
      loading: {
        ...state.version.loading || {},
        [action.uuid]: true
      }
    }
  })),

  on(ApplicationInstanceActions.loadVersionSuccess, (state: State, action) => ({
    ...state,
    version: versionAdapter.setOne(action.result, {
      ...state.version,
      loading: {
        ...state.version.loading || {},
        [action.uuid]: false
      },
      loaded: {
        ...state.version.loaded || {},
        [action.uuid]: true
      }
    })
  })),

  on(ApplicationInstanceActions.loadVersionFailure, (state: State, action) => ({
    ...state,
    version: {
      ...state.version,
      loading: {
        ...state.version.loading || {},
        [action.uuid]: false
      }
    }
  })),
);
