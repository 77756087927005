import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromServiceMarketplace from './service_marketplace.reducer';
import * as _ from 'lodash';
import { selectRouteNestedParam } from '../router/router.selectors';

const selectors = fromServiceMarketplace.adapter.getSelectors((state: fromServiceMarketplace.State) => state || fromServiceMarketplace.initialState);
const listSelectors = fromServiceMarketplace.listAdapter.getSelectors((state: fromServiceMarketplace.State) => state?.list || fromServiceMarketplace.listAdapterInitialState);
const applicationListSelectors = fromServiceMarketplace.applicationListAdapter.getSelectors((state: fromServiceMarketplace.State) => state?.applicationList || fromServiceMarketplace.applicationListInitialState);
const applicationSelectors = fromServiceMarketplace.applicationAdapter.getSelectors((state: fromServiceMarketplace.State) => state?.application || fromServiceMarketplace.applicationInitialState);
const planDetailSelectors = fromServiceMarketplace.planAdapter.getSelectors((state: fromServiceMarketplace.State) => state?.plan || fromServiceMarketplace.planDetailInitialState);
const versionDetailSelectors = fromServiceMarketplace.versionAdapter.getSelectors((state: fromServiceMarketplace.State) => state?.version || fromServiceMarketplace.versionDetailInitialState);

export const selectServiceMarketplaceState = createFeatureSelector<fromServiceMarketplace.State>(fromServiceMarketplace.featureKey);

export const getApplicationInstanceListConfig = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'listConfig'], {}));
export const getApplicationInstances = createSelector(selectServiceMarketplaceState, listSelectors.selectAll);
export const getApplicationInstancesMeta = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'meta'], { total: 0, count: 0, offset: 0, page: 0, limit: 0 }));
export const getApplicationInstancesLinks = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'links']));
export const isApplicationInstanceListLoading = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'loading'], false));
export const isApplicationInstanceListLoadingNext = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'loadingNext'], false));
export const isApplicationInstanceListLoaded = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['list', 'loaded'], false));

export const getApplicationInstanceData = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(selectors.selectEntities(state), [props.uuid, 'data']));
export const getApplicationInstanceIncluded = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(selectors.selectEntities(state), [props.uuid, 'included']));
export const isApplicationInstanceLoading = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['loading', props.uuid], false));
export const isApplicationInstanceLoaded = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['loaded', props.uuid], false));
export const isApplicationInstanceBusy = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => {
  return (_.get(selectors.selectEntities(state), [props.uuid, 'data', 'attributes', 'state']) || _.get(listSelectors.selectEntities(state), [props.uuid, 'attributes', 'state'])) !== 'active';
});
export const canApplicationInstanceBeDeleted = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, isApplicationInstanceBusy(props), (state, busy) => !busy);
export const getCannotDeleteReason = (props: { uuid: string }) => createSelector(
  isApplicationInstanceBusy(props),
  (busy) => {
    if (busy) {
      return 'APPLICATIONS.NO_DELETE_WHILE_LOADING';
    }
    return undefined;
  }
);

export const getSelectedApplicationInstanceData = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_instance_uuid'), (state: fromServiceMarketplace.State, application_instance_uuid) => _.get(selectors.selectEntities(state), [application_instance_uuid, 'data']));
export const getSelectedApplicationInstanceIncluded = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_instance_uuid'), (state: fromServiceMarketplace.State, application_instance_uuid) => _.get(selectors.selectEntities(state), [application_instance_uuid, 'included']));
export const isSelectedApplicationInstanceLoading = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_instance_uuid'), (state: fromServiceMarketplace.State, application_instance_uuid) => _.get(state, ['loading', application_instance_uuid], false));
export const isSelectedApplicationInstanceLoaded = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_instance_uuid'), (state: fromServiceMarketplace.State, application_instance_uuid) => _.get(state, ['loaded', application_instance_uuid], false));
export const isSelectedApplicationInstanceBusy = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_instance_uuid'), (state: fromServiceMarketplace.State, application_instance_uuid) => {
  return (_.get(selectors.selectEntities(state), [application_instance_uuid, 'data', 'attributes', 'state']) || _.get(listSelectors.selectEntities(state), [application_instance_uuid, 'attributes', 'state'])) !== 'active';
});


/************ Application */

export const getApplicationListConfig = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'listConfig'], {}));
export const getApplications = createSelector(selectServiceMarketplaceState, applicationListSelectors.selectAll);
export const getApplicationsMeta = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'meta'], { count: 0, total: 0, offset: 0, page: 0, limit: 0 }));
export const getApplicationsLinks = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'links']));
export const isApplicationListLoading = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'loading'], false));
export const isApplicationListLoadingNext = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'loadingNext'], false));
export const isApplicationListLoaded = createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['applicationList', 'loaded'], false));


export const getApplicationData = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(applicationSelectors.selectEntities(state), [props.uuid, 'data']));
export const getApplicationIncluded = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(applicationSelectors.selectEntities(state), [props.uuid, 'included']));
export const isApplicationLoading = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['application', 'loading', props.uuid], false));
export const isApplicationLoaded = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['application', 'loaded', props.uuid], false));
export const isApplicationBusy = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => {
  return (_.get(applicationSelectors.selectEntities(state), [props.uuid, 'data', 'attributes', 'state']) || _.get(applicationListSelectors.selectEntities(state), [props.uuid, 'attributes', 'state'])) !== 'active';
});
export const getSelectedApplicationData = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_uuid'), (state: fromServiceMarketplace.State, application_uuid) => _.get(applicationSelectors.selectEntities(state), [application_uuid, 'data']));
export const getSelectedApplicationIncluded = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_uuid'), (state: fromServiceMarketplace.State, application_uuid) => _.get(applicationSelectors.selectEntities(state), [application_uuid, 'included']));
export const isSelectedApplicationLoading = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_uuid'), (state: fromServiceMarketplace.State, application_uuid) => _.get(state, ['application', 'loading', application_uuid], false));
export const isSelectedApplicationLoaded = createSelector(selectServiceMarketplaceState, selectRouteNestedParam('application_uuid'), (state: fromServiceMarketplace.State, application_uuid) => _.get(state, ['application', 'loaded', application_uuid], false));



// export const getPlanListConfig = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'listConfig'], {}));
// export const getPlans = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, planListSelectors({ versionUuid: props.versionUuid }).selectAll);
// export const getPlansMeta = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'meta']));
// export const getPlansLinks = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'links']));
// export const isPlanListLoading = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'loading'], false));
// export const isPlanListLoadingNext = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'loadingNext'], false));
// export const isPlanListLoaded = (props: { versionUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['planList', props.versionUuid, 'loaded'], false));

export const getPlan = (props: { planUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(planDetailSelectors.selectEntities(state), props.planUuid));
export const isPlanLoaded = (props: { planUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['plan', 'loaded', props.planUuid], false));
export const isPlanLoading = (props: { planUuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['plan', 'loading', props.planUuid], false));

export const getVersion = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(versionDetailSelectors.selectEntities(state), props.uuid));
export const isVersionLoaded = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['version', 'loaded', props.uuid], false));
export const isVersionLoading = (props: { uuid: string }) => createSelector(selectServiceMarketplaceState, (state: fromServiceMarketplace.State) => _.get(state, ['version', 'loading', props.uuid], false));
