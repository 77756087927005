import { RequestOptions } from '@gridscale/gsclient-js';
import { createAction, props } from '@ngrx/store';
import { MarketplaceApplicationDetailResponse, MarketplaceApplicationListResponse, MarketplacePlanDetailResponse, MarketplaceVersionDetailResponse, MarketplaceApplicationInstanceCreateResult, MarketplaceApplicationInstanceDetailResponse, MarketplaceApplicationInstanceListResponse } from '@gridscale/gsclient-js';

export const actionPrefix = '[service-marketplace]';

export const setApplicationInstancesListConfig = createAction(actionPrefix + ' Set Application instance List Config', props<{ config: RequestOptions }>());

export const loadApplicationInstances = createAction(actionPrefix + ' load application instances');
export const loadApplicationInstancesSuccess = createAction(actionPrefix + ' load application instances success', props<{ result: MarketplaceApplicationInstanceListResponse }>());
export const loadApplicationInstancesFailure = createAction(actionPrefix + ' load application instances failure', props<{ error: Error }>());

export const loadApplicationInstancesNext = createAction(actionPrefix + ' load application instances next');
export const loadApplicationInstancesNextSuccess = createAction(actionPrefix + ' load application instances next success', props<{ result: MarketplaceApplicationInstanceListResponse }>());
export const loadApplicationInstancesNextFailure = createAction(actionPrefix + ' load application instances next failure', props<{ Error: Error }>());

export const loadApplicationInstance = createAction(actionPrefix + ' load application instance', props<{ uuid: string; }>());
export const loadApplicationInstanceSuccess = createAction(actionPrefix + ' load application instance success', props<{ uuid: string; result: MarketplaceApplicationInstanceDetailResponse }>());
export const loadApplicationInstanceFailure = createAction(actionPrefix + ' load application instance failure', props<{ uuid: string; error: Error }>());
export const loadSelectedApplicationInstance = createAction(actionPrefix + ' load selected application instance');

export const requestDeleteApplicationInstance = createAction(actionPrefix + ' delete application instance', props<{ uuid: string; }>());
export const requestDeleteApplicationInstanceSuccess = createAction(actionPrefix + ' delete application instance success', props<{ uuid: string; }>());
export const requestDeleteApplicationInstanceFailure = createAction(actionPrefix + ' delete application instance failure', props<{ uuid: string; error: Error }>());
export const requestDeleteApplicationInstanceWithConfirm = createAction(actionPrefix + ' delete application instance with confirm', props<{ uuid: string; }>());

export const setApplicationsListConfig = createAction(actionPrefix + ' Set Applications List Config', props<{ config: RequestOptions }>());

export const loadApplications = createAction(actionPrefix + ' load Applications');
export const loadApplicationsSuccess = createAction(actionPrefix + ' load Applications success', props<{ result: MarketplaceApplicationListResponse }>());
export const loadApplicationsFailure = createAction(actionPrefix + ' load Applications failure', props<{ error: Error }>());

export const loadApplicationsNext = createAction(actionPrefix + ' load Applications next');
export const loadApplicationsNextSuccess = createAction(actionPrefix + ' load Applications next success', props<{ result: MarketplaceApplicationListResponse }>());
export const loadApplicationsNextFailure = createAction(actionPrefix + ' load Applications next failure', props<{ error: Error }>());

export const loadApplication = createAction(actionPrefix + ' load Application', props<{ uuid: string }>());
export const loadApplicationSuccess = createAction(actionPrefix + ' load Application success', props<{ uuid: string, result: MarketplaceApplicationDetailResponse }>());
export const loadApplicationFailure = createAction(actionPrefix + ' load Application failure', props<{ uuid: string, error: Error }>());
export const loadSelectedApplication = createAction(actionPrefix + ' load selected Application');

export const loadPlan = createAction(actionPrefix + ' load plan', props<{ planUuid: string }>());
export const loadPlanSuccess = createAction(actionPrefix + ' load plan success', props<{ planUuid: string, result: MarketplacePlanDetailResponse }>());
export const loadPlanFailure = createAction(actionPrefix + ' load plan failure', props<{ planUuid: string, error: Error }>());

export const requestApplication = createAction(actionPrefix + ' request application', props<{ applicationUuid: string; applicationName: string, planUuid: string, versionUuid: string, planSettings?: Record<string, any> }>());
export const requestApplicationSuccess = createAction(actionPrefix + ' request application success', props<{ result: MarketplaceApplicationInstanceCreateResult }>());
export const requestApplicationFailure = createAction(actionPrefix + ' request application failure', props<{ error: Error }>());

export const loadVersion = createAction(actionPrefix + 'load version', props<{ uuid: string }>());
export const loadVersionSuccess = createAction(actionPrefix + 'load version success', props<{ uuid: string; result: MarketplaceVersionDetailResponse }>());
export const loadVersionFailure = createAction(actionPrefix + 'load version failre', props<{ uuid: string; error: Error }>());
